import { UnauthenticatedStrategy } from '~/auth/strategies/unauthenticated/unauthenticated.strategy';
import { IStrategyByEnum, StrategyFactory } from '~/auth/strategies/strategy-factory';
import { useAuthStore } from '~/store/auth.store';
import { IStrategy } from '~/auth/interfaces/strategy';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';
import { GenericFunction } from '~/@types/helpers';

/**
 * Wrapper around the chosen strategy
 *
 * NOTE: Use the AuthService instead of creating a new instance of this class
 */
class AuthServiceClass {
  private strategy: IStrategy = new UnauthenticatedStrategy();

  async switchStrategy(strategy: EAuthStrategy | IStrategy) {
    const prevStrategy = this.strategy;
    const prevStrategyCtr = prevStrategy.constructor;

    if (
      prevStrategy &&
      prevStrategyCtr !== strategy.constructor &&
      prevStrategyCtr !== UnauthenticatedStrategy
    ) {
      await this.strategy.onLogout();
    }

    if (typeof strategy === 'string') {
      this.strategy = StrategyFactory.create(strategy);
    } else {
      this.strategy = strategy;
    }

    this.strategy.updateAuthorizedRoutes();

    useAuthStore.setState({ authStrategy: this.getStrategyEnum(strategy) });

    return this;
  }

  async authenticate<T extends EAuthStrategy>(
    strategy: T,
    options: Parameters<IStrategyByEnum[T]['authenticate']>[0],
  ) {
    try {
      useAuthStore.setState({ blockRenders: true });
      if (useAuthStore.getState().authStrategy !== EAuthStrategy.Unauthenticated) {
        await this.logout();
      }

      const authenticatingStrategy = StrategyFactory.create(strategy);
      await authenticatingStrategy.authenticate(options as never);
      await this.switchStrategy(authenticatingStrategy);
      await this.strategy.triggerGatekeeperValidation();
    } finally {
      useAuthStore.setState({ blockRenders: false });
    }
  }

  /**
   * Updates all the authentication-related data (dependents, user, products, etc.)
   */
  updateAllAuthData() {
    return this.strategy.updateAllAuthData();
  }

  /**
   * Updates the user context with profile data (name, cpf, address, etc.)
   */
  updateProfileData() {
    return this.strategy.updateProfileData();
  }

  updateDependents() {
    return this.strategy.updateMyDependents();
  }

  async getToken(): Promise<null | string> {
    return this.strategy.getToken();
  }

  async getRefreshToken(): Promise<null | string> {
    return this.strategy.getRefreshToken();
  }

  async logout() {
    await this.switchStrategy(EAuthStrategy.Unauthenticated);
  }

  async refreshToken() {
    return this.strategy.refreshToken();
  }

  async selectProduct(onix: string) {
    return this.strategy.selectProduct(onix);
  }

  async triggerGatekeeperValidation() {
    return this.strategy.triggerGatekeeperValidation();
  }

  onBeforeTokenExpire() {
    return this.strategy.onBeforeTokenExpire();
  }

  onTokenExpire() {
    return this.strategy.onTokenExpire();
  }

  getAuthorizedRoutes(): Routes[] {
    return this.strategy.getAuthorizedRoutes();
  }

  onNotFound() {
    return this.strategy.onNotFound();
  }

  getSelectedOnix() {
    return useAuthStore.getState().selectedProduct?.onixCode ?? null;
  }

  getCommonRoutes(): Routes[] {
    return ['DeepLink', 'MagicLink', 'SSOCertificate', 'SSOCertificateFull', 'NotFound', 'DeepLinkLoggedOut'];
  }

  isFullAuthenticated() {
    return this.strategy.isFullAuthenticated();
  }

  async onStoreLoaded() {
    const strategy = StrategyFactory.create(useAuthStore.getState().authStrategy);
    if (strategy) {
      await this.switchStrategy(strategy);
      await strategy.onStoreLoaded();
      if (strategy.constructor !== UnauthenticatedStrategy) {
        // Running in parallel
        void this.updateAllAuthData();
        await strategy.triggerGatekeeperValidation();
      }
    }
  }

  getInitialParams() {
    return this.strategy.getInitialParams();
  }

  private getStrategyEnum(strategy: EAuthStrategy | IStrategy): EAuthStrategy {
    if (typeof strategy === 'string') {
      return strategy;
    }

    return (strategy.constructor as GenericFunction & { StrategyName: string })
      .StrategyName as EAuthStrategy;
  }
}

export const AuthService = new AuthServiceClass();
