import { RequestDetailsItem } from './RequestDetailsItem';
import { RequestDetailsRoot } from './RequestDetailsRoot';
import { RequestDetailsStep } from './RequestDetailsStep';
import { RequestDetailsStepItem } from './RequestDetailsStepItem';

export const RequestDetails = {
  Root: RequestDetailsRoot,
  Item: RequestDetailsItem,
  Step: RequestDetailsStep,
  StepItem: RequestDetailsStepItem,
};
