import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { ScheduleTimeRestriction } from '../ScheduleAppointment/components/ScheduleTimeRestriction';
import { scheduleCheckup } from './components/CheckupSteps';
import { PageWithCard, Stepper, useSweetAlert } from '~/components/@hello-ui';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { useAuth } from '~/auth/legacy/useAuth';

const ALLOWED_FEATURES = ['checkup_carencia_30', 'checkup_carencia_60', 'checkup_carencia_90'];

export const ScheduleCheckup = () => {
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { clearAppointments } = useAppointment();
  const navigation = useNavigation();
  const { partner } = useAuth();

  const filteredFeatures = partner?.features?.filter(
    ({ active, name }) => active === 1 && ALLOWED_FEATURES.includes(name),
  );

  const gracePeriod = filteredFeatures?.length ? filteredFeatures[0].name.split('_')[2] : '';

  const initialDate = partner?.data_user?.data_inicio_associacao || null;

  function calcularCarencia(carenciaEmDias: number, dataAdesao: string) {
    const dataAdesaoDate = new Date(dataAdesao);
    const dataAtual = new Date();
    dataAdesaoDate.setHours(0, 0, 0, 0);
    dataAtual.setHours(0, 0, 0, 0);
    const diferencaEmMilissegundos = dataAtual.getTime() - dataAdesaoDate.getTime();
    const diasPassados = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));
    const diasRestantes = carenciaEmDias - diasPassados;
    return diasRestantes > 0 ? diasRestantes : 0;
  }

  const gracePeriodDays = calcularCarencia(gracePeriod, initialDate);

  const {
    scrollDisabled,
    customPadding,
    finalizeSteps,
    setCurrentStepState,
    overrideBodyMaxWidth,
  } = useStepper();

  const handleGoBack = () => {
    showSweetAlert(
      'Cancelar solicitação',
      'Tem certeza que deseja sair da solicitação de agendamento?',
      'info',
      false,
      false,
      {
        layout: 'helloUi',
        buttons: [
          {
            text: 'Não',
            variant: 'outline',
            onPress: () => {
              hideSweetAlert();
            },
          },
          {
            text: 'Sim',
            variant: 'primary',
            onPress: () => {
              setCurrentStepState(0);
              clearAppointments();
              finalizeSteps();
              navigation.navigate('ScheduleAppointment');

              showSweetAlert(
                'Tudo certo!',
                'Sua solicitação foi cancelada',
                'success',
                false,
                false,
                {
                  layout: 'helloUi',
                  buttons: [
                    {
                      variant: 'primary',
                      text: 'Ok',
                      onPress: () => {
                        hideSweetAlert();
                      },
                    },
                  ],
                },
              );
            },
          },
        ],
      },
    );
  };

  return (
    <PageWithCard
      simpleOptions={{
        onBack: handleGoBack,
        title: 'Solicitação de Check-Up',
      }}
      overrideBodyMaxWidth={overrideBodyMaxWidth}
      overrideScrollEnabled={!scrollDisabled}
      overridePadding={customPadding}>
      {gracePeriodDays === 0 ? (
        <Stepper items={scheduleCheckup()} />
      ) : (
        <ScheduleTimeRestriction grancePeriodDays={gracePeriodDays} />
      )}
    </PageWithCard>
  );
};
