import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const SeniorAssistanceIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 14 14" fill="none">
      <Path
        d="M4.66667 1.66667C4.66667 0.746 5.41267 0 6.33333 0C7.254 0 8 0.746 8 1.66667C8 2.58733 7.254 3.33333 6.33333 3.33333C5.41267 3.33333 4.66667 2.58733 4.66667 1.66667ZM11.3333 9.33333V15.3333C11.3333 15.7013 11.0347 16 10.6667 16C10.2987 16 10 15.7013 10 15.3333V9.73267C9.088 9.21333 8.12267 8.43867 7.322 7.602C7.28 8.51067 7.29467 9.61933 7.312 10.8547L7.326 11.9753C7.33133 12.5147 7.12533 13.0227 6.746 13.406C6.53267 13.6213 6.27667 13.776 6.00067 13.8753V15.3333C6.00067 15.7013 5.702 16 5.334 16C4.966 16 4.66733 15.7013 4.66733 15.3333V14H3.334V15.3333C3.334 15.7013 3.03533 16 2.66733 16C2.29933 16 2.00067 15.7013 2.00067 15.3333V13.8747C1.716 13.772 1.45467 13.612 1.238 13.3867C0.855999 12.9887 0.657333 12.4653 0.679999 11.912C0.884666 6.81 2.41867 4 5.00067 4C6.154 3.92733 6.97333 4.93933 7.53933 5.806C8.382 6.944 9.79066 8.14933 10.966 8.73667C11.192 8.84933 11.3347 9.08067 11.3347 9.33333H11.3333ZM5.79267 5.69C5.59 5.45933 5.30733 5.33333 5 5.33333C2.474 5.33333 2.074 10.41 2.01133 11.9653C1.98867 12.3367 2.30467 12.6747 2.678 12.6667H5.32533C5.686 12.6747 6.00467 12.3493 5.992 11.9893L5.978 10.8727C5.954 9.08933 5.93467 7.68 6.064 6.57133C6.10133 6.248 6.00267 5.92733 5.79267 5.69Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default SeniorAssistanceIcon;
