import React from 'react';
import { ClipPath, Defs, G, Path, Svg } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const AlertIconOutlined = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} fill="none">
      <G fill="#FA9F47" clipPath="url(#a)">
        <Path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm0 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Z" />
        <Path d="M12 5a1 1 0 0 0-1 1v8a1 1 0 0 0 2 0V6a1 1 0 0 0-1-1ZM13 18a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
      </G>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
