import React from 'react';
import { ClipPath, Defs, G, Path, Rect, Svg } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

type IconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export const ResidentialInspectionIcon = ({ width = 28, height = 28, color }: IconProps) => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 28 28" fill="none">
      <G>
        <Path
          d="M17 12.0006C13.141 12.0006 10 15.1416 10 19.0006C10 22.8596 13.141 26.0006 17 26.0006C20.859 26.0006 24 22.8596 24 19.0006C24 15.1416 20.859 12.0006 17 12.0006ZM17 24.0006C14.243 24.0006 12 21.7576 12 19.0006C12 16.2436 14.243 14.0006 17 14.0006C19.757 14.0006 22 16.2436 22 19.0006C22 21.7576 19.757 24.0006 17 24.0006ZM16 18.0006V16.0006C16 15.4476 16.447 15.0006 17 15.0006C17.553 15.0006 18 15.4476 18 16.0006V18.0006C18 18.5536 17.553 19.0006 17 19.0006C16.447 19.0006 16 18.5536 16 18.0006ZM18.5 21.5006C18.5 22.3286 17.828 23.0006 17 23.0006C16.172 23.0006 15.5 22.3286 15.5 21.5006C15.5 20.6726 16.172 20.0006 17 20.0006C17.828 20.0006 18.5 20.6726 18.5 21.5006ZM10 25.0006C10 25.5536 9.552 26.0006 9 26.0006H5C2.243 26.0006 0 23.7576 0 21.0006V11.7246C0 10.0596 0.824 8.51059 2.204 7.57959L9.203 2.85559C10.902 1.70959 13.098 1.70959 14.797 2.85559L22 7.74859C22.99 8.49659 23.692 9.59259 23.918 10.8186C24.019 11.3616 23.659 11.8826 23.116 11.9836C22.575 12.0866 22.051 11.7246 21.951 11.1816C21.805 10.3936 21.342 9.68459 20.679 9.23759L13.678 4.51359C12.658 3.82459 11.342 3.82459 10.323 4.51359L3.322 9.23759C2.494 9.79659 2 10.7246 2 11.7236V20.9996C2 22.6536 3.346 23.9996 5 23.9996H9C9.552 23.9996 10 24.4476 10 25.0006Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_17587_49331">
          <Rect
            width="24"
            height="24"
            fill={color ?? theme.colors.primary}
            transform="translate(0 2)"
          />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
