import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../../assets/images/SeniorAssistancePickUpAndDropOffService/desktop.png';
import mobileBackground from '../../../assets/images/SeniorAssistancePickUpAndDropOffService/mobile.png';
import { SeniorAssistanceContactMethods } from '../components/ContactMethods';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { PickUpAndDropOffServiceIcon } from '~/components/Icons/PickUpAndDropOffService';
import { goBack } from '~/helpers';

export const SeniorAssistancePickUpAndDropOffService = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        backgroundImage,
        titleStartContent: <PickUpAndDropOffServiceIcon color={theme.colors.white} />,
        title: 'Leva e Trás',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Transporte seguro para levar e buscar você em agendamentos médicos, com toda a comodidade
          que o seu dia precisa.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Ideal para quem precisa de companhia durante agendamentos, como consultas médicas,
            exames ou compromissos diversos.
          </Typography>
          <View className="mb-8 mt-8">
            <Typography variant="body2">
              O serviço oferece um veículo confiável e preparado para transportar o público sênior
              em consultas, exames ou tratamentos médicos. Com motoristas capacitados e atenção
              especial, garantimos um trajeto tranquilo do início ao fim.
            </Typography>
          </View>

          <View className=" mt-8">
            <Typography variant="body2">
              O serviço inclui acompanhamento desde a saída de casa até o retorno, proporcionando
              mais praticidade e segurança para você se preocupar apenas com o que realmente
              importa: a sua saúde.
            </Typography>
          </View>

          <View className=" mt-8">
            <Typography variant="body2">
              <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
              acionamentos ao ano.
            </Typography>
          </View>

          <View className=" mt-8">
            <Typography variant="body2">
              Para mais detalhes consulte o regulamento do seu benefício.
            </Typography>
          </View>
        </Collapse>
        <Collapse title="Como utilizar">
          <Typography variant="body2">
            Agende o serviço com antecedência, informando a data, horário e endereço de saída e
            destino. No dia combinado, o motorista estará no local e horário marcados para levar e
            trazer você com todo o cuidado e pontualidade.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <SeniorAssistanceContactMethods />
    </PageWithCard>
  );
};
