import { ICertificateAuthenticateOptions, ICertificateFullAuthenticateOptions } from '~/auth/strategies/certificate/interfaces/certificate-authenticate-options';
import { IGatekeeper } from '~/auth/interfaces/gatekeeper';
import { BaseStrategy } from '~/auth/strategies/base-strategy';
import { OlimpoService } from '~/services/api/olimpo/olimpo-service-class';
import { useAuthStore } from '~/store/auth.store';
import { navigationRef } from '~/router/navigator';
import { CompleteDataSSOGatekeeper } from '~/auth/gatekeepers/complete-data-sso-gatekeeper';
import { EAuthStrategy } from '~/auth/enums/auth-strategy';

export class CertificateFullStrategy extends BaseStrategy {
  constructor() {
    super();
  }

  static StrategyName = EAuthStrategy.CertificateFull;

  getGateKeepers(): IGatekeeper[] {
    return [new CompleteDataSSOGatekeeper()];
  }

  override async onGatekeepersPass(): Promise<void> {
    await super.onGatekeepersPass();
    navigationRef.navigate('Dashboard');
  }

  async authenticate(options: ICertificateFullAuthenticateOptions): Promise<void> {
    const res = await OlimpoService.ssoCertificateFull.authenticateCertificate(options);

    useAuthStore.setState({
      token: res.token,
      refreshToken: res.refreshToken,
    });

    await this.updateMyProducts();
    await this.updateProfileData();
    await this.selectProduct(res.onixCode);
  }

  override async onTokenExpire() {
    await super.onTokenExpire();
    navigationRef.navigate('DeepLinkLoggedOut');
  }
}
