import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Linking, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../../assets/images/SeniorAssistanceResidentialInspection/desktop.png';
import mobileBackground from '../../../assets/images/SeniorAssistanceResidentialInspection/mobile.png';
import { Button, Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import { CallIcon } from '~/components/Icons/Call';
import { ResidentialInspectionIcon } from '~/components/Icons/ResidentialInspection';
import { goBack } from '~/helpers';

export const SeniorAssistanceResidentialInspection = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        backgroundImage,
        titleStartContent: <ResidentialInspectionIcon color={theme.colors.white} />,
        title: 'Vistoria Residencial',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Serviço de chaveiro para <Typography variant="bodyHighlight2">mais segurança </Typography>
          e <Typography variant="bodyHighlight2">conforto</Typography> em casa com uma vistoria que
          identifica e sugere melhorias essenciais para o dia a dia. O serviço é pensado para tornar
          o ambiente mais seguro e funcional.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            Um profissional especializado realiza uma{' '}
            <Typography variant="bodyHighlight2">avaliação completa do imóvel </Typography>,
            identificando riscos como móveis mal posicionados, falta de apoio em áreas importantes,
            pisos escorregadios e outros pontos que possam comprometer a mobilidade e a segurança.
          </Typography>
          <View className="mb-8 mt-8">
            <Typography variant="body2">
              Ao final, você recebe um{' '}
              <Typography variant="bodyHighlight2">laudo detalhado</Typography> com todas as
              <Typography variant="bodyHighlight2">sugestões</Typography> de{' '}
              <Typography variant="bodyHighlight2">adequações necessárias</Typography>, com
              orientações práticas para adaptar o lar às suas necessidades.
            </Typography>
          </View>
          <Typography variant="body2">
            <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a 3
            acionamentos ao ano.
          </Typography>
        </Collapse>
        <Collapse title="Como utilizar">
          <Typography variant="body2">
            Agende uma visita no dia e horário que preferir, receba o profissional para uma análise
            completa da sua casa. Ao fim consulte o laudo entregue com todas as recomendações para
            tornar o seu lar mais seguro e funcional.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        {theme.isMobile ? (
          <>
            <Typography variant="bodyHighlight2">
              Para acionamento ou dúvidas, ligue para a Central de Atendimento:
            </Typography>
            <Typography variant="body2" className="mt-8">
              Para capitais e regiões metropolitanas
            </Typography>
          </>
        ) : (
          <Typography variant="bodyHighlight2">
            Para acionamento ou dúvidas, ligue para a Central de Atendimento:
          </Typography>
        )}
      </View>

      {theme.isMobile ? (
        <View className="w-full">
          <Button
            className="w-full"
            icon={<CallIcon color={theme.colors.background} />}
            iconPosition="left"
            variant="primary"
            onPress={() => Linking.openURL('tel:1140001640')}>
            (11) 4000-1640
          </Button>
          <View className="mt-16 w-full items-center self-center text-center">
            <Typography variant="body2">Para demais regiões</Typography>
            <Button
              icon={<CallIcon color={theme.colors.primary} />}
              iconPosition="left"
              variant="outline"
              onPress={() => Linking.openURL('tel:08008368836')}>
              0800 836 8836
            </Button>
          </View>
          <View className="mt-16 w-full items-center self-center text-center">
            <Typography variant="body1">Segunda à Sexta das 7h às 19h</Typography>
            <Typography variant="body2">Exceto feriados nacionais</Typography>
          </View>
        </View>
      ) : (
        <View className="flex h-[210px] w-full rounded-lg bg-background-gray pb-24 pl-32 pr-32 pt-24 text-center">
          <View className="flex w-full flex-row">
            <View className="basis-1/2 text-left">
              <View className="w-full">
                <Typography variant="body1" color={theme.colors.primary}>
                  (11) 4000-1640
                </Typography>
                <Typography variant="body2">Capitais e regiões metropolitanas:</Typography>
              </View>
            </View>
            <View className="basis-1/2 text-left">
              <Typography variant="body1" color={theme.colors.primary}>
                0800 836 88 36
              </Typography>
              <Typography variant="body2">Demais localidades</Typography>
            </View>
          </View>
          <View className="mt-24 w-full text-left">
            <Typography variant="body1">Segunda à Sexta das 7h às 19h</Typography>
            <Typography variant="body2">Exceto feriados nacionais</Typography>
          </View>
        </View>
      )}
    </PageWithCard>
  );
};
