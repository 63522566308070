import React, { useState } from 'react';
import { useSweetAlert } from '~/components/@hello-ui';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { useTemPay } from '~/hooks/temPay';
import { PaymentConfirm } from '~/screens/TemPayRecharge/components/PaymentConfirm';

export const PaymentBillet = () => {
  const api = useApi();
  const { user, onixCode } = useAuth();
  const { card } = useTemPay();
  const { setRechargeStep, rechargeAmount, setBilletUrl, setBilletCode } = useRecharge();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  const [loading, setLoading] = useState(false);

  const getErrorMessage = (status: number, data) => {
    switch (status) {
      case 500:
        return 'Ocorreu um erro inesperado';
      case 409:
        return data?.message;
      default:
        return 'Ocorreu um erro inesperado';
    }
  };

  const showErrorAlert = (message: string) => {
    showSweetAlert('Ops, algo deu errado', message, 'error', false, false, {
      layout: 'helloUi',
      touchOutside: false,
      buttons: [
        {
          text: 'OK',
          variant: 'primary',
          onPress: () => hideSweetAlert(),
        },
      ],
    });
  };

  const onNext = async () => {
    setLoading(true);

    try {
      const { data } = await api.recharge({
        cpf: user?.cpf.replace(/\D/g, ''),
        cardNumber: card?.numero_cartao,
        type: 'billets',
        totalAmount: rechargeAmount,
        onix_code: onixCode?.onix_code,
      });

      setBilletUrl(data.url);
      setBilletCode(data.codigoBoleto);

      setRechargeStep(5);
    } catch (err) {
      const status = err.response.status;
      const errorMessage = getErrorMessage(status, err.response.data);

      showErrorAlert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaymentConfirm
      title="Pagamento por Boleto"
      isLoading={loading}
      description={
        'Será cobrado uma taxa de R$2,50 por emissão. Só falta você confirmar a sua solicitação de recarga para a emissão do seu boleto.'
      }
      onConfirm={onNext}
    />
  );
};
