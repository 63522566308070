import React, { useState } from 'react';

import { View } from 'react-native';

import { useNavigation } from '@react-navigation/native';

import { useTheme } from 'styled-components/native';

import { ProgressBar } from './components/ProgressBar';
import { CHECKUP_STEPS } from './components/Steps/checkup';

import { AppointmentFooter } from './components/AppointmentFooter';
import { RequestDetails } from './components/RequestDetails';
import { Collapse, PageWithCard, Typography } from '~/components/@hello-ui';

import { NavigationProps } from '~/@types/navigation/routes-helpers';
import { Wrapper } from '~/components/@tem-ui';
import { Spacing } from '~/components/Spacing';
import { AlertIconOutlined } from '~/components/Icons/AlertIconOutlined';

type Step = {
  title: string;
  status: string;
  address?: string;
  date?: Date;
  cancelReason?: string;
};

type Appointment = {
  type: string;
  patient: string;
  patientAddress: string;
  specialty: string;
  status: 'in-progress' | 'completed' | 'closed';
  step: number;
  currentStepTitle: string;
  createdAt: Date;
  steps: Step[];
};

type AppointmentDetailsProps = NavigationProps<'AppointmentDetails'>;

export const AppointmentDetails = ({ route }: AppointmentDetailsProps) => {
  const [appointment, setAppointment] = useState<Appointment>({
    type: 'Check-up',
    specialty: 'Clinica Médica',
    status: 'in-progress',
    step: 2,
    currentStepTitle: 'Agendado',
    patient: 'oiqgj',
    patientAddress: 'Endereço completo',
    createdAt: new Date(),
    steps: [
      {
        title: 'Primeira Consulta',
        status: 'Encerrado',
        address: 'Av. Paulista, 1578 - Bela Vista, São Paulo - SP, 01310-200',
        date: new Date(),
        cancelReason: 'Qualquer um',
      },
    ],
  });

  const navigation = useNavigation();
  const theme = useTheme();

  const { protocolo } = route.params;

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Dashboard');
    }
  };

  const totalSteps = CHECKUP_STEPS.reduce((accumulator, current) => {
    return accumulator + current.steps.length;
  }, 0);

  return (
    <PageWithCard simpleOptions={{ title: 'Acompanhar Solicitação', onBack: handleGoBack }}>
      <View className="pb-24">
        <ProgressBar
          data={CHECKUP_STEPS}
          currentStep={appointment.step}
          status={appointment.status}
        />

        <View className="mt-6 w-full">
          <Typography variant="body2" className="mb-8 text-sm">
            Check-Up Médico
          </Typography>

          {appointment.status === 'in-progress' ? (
            <>
              <Typography variant="body2" className="mb-8 text-sm">
                Etapa {appointment.step + 1} de {totalSteps}:{' '}
                <Typography variant="bodyHighlight2" className="text-sm">
                  {appointment.currentStepTitle}
                </Typography>
              </Typography>

              <Typography variant="body2" className="text-sm">
                Protocolo:{' '}
                <Typography variant="bodyHighlight2" className="text-sm">
                  {protocolo}
                </Typography>
              </Typography>
            </>
          ) : (
            <Typography
              variant="bodyHighlight2"
              className="rounded-lg p-8 text-center text-sm"
              style={{ backgroundColor: theme.colors.gray15 }}>
              {appointment.status === 'closed' ? 'Encerrado' : 'Concluído'}
            </Typography>
          )}
        </View>
      </View>

      {appointment.status === 'in-progress' && (
        <Collapse title="O que fazer agora?">
          <View className="justify-content: flex-start; flex">
            <Typography variant="body2" color={theme.colors.black70}>
              Nos <Typography variant="bodyHighlight2">envie seu pedido médico</Typography> por
              WhatsApp <Typography variant="body2">para seguir à próxima etapa.</Typography>{' '}
            </Typography>
            <Wrapper
              style={{
                backgroundColor: '#FEEED1',
                marginTop: 10,
                padding: 20,
              }}>
              <View style={{ alignItems: 'flex-start' }}>
                <View
                  style={{
                    flexDirection: 'row',
                  }}>
                  <AlertIconOutlined width={30} height={30} />
                  <Typography variant="body2" color={theme.colors.orange14}>
                    Importante
                  </Typography>{' '}
                </View>
                <Typography variant="bodyHighlight2" color={theme.colors.black70}>
                  Não é necessário solicitar um novo agendamento{' '}
                  <Typography variant="body2">para cada etapa do seu Checku-Up Médico.</Typography>{' '}
                </Typography>
                <Spacing top={10}>
                  <Typography variant="body2" color={theme.colors.black70}>
                    Acompanhe o agendamento da sua primeira consulta, exame e consulta de retorno
                    nesta mesma solicitação{' '}
                  </Typography>
                </Spacing>
              </View>
            </Wrapper>
          </View>
        </Collapse>
      )}

      <Collapse title="Detalhes da solicitação">
        <RequestDetails.Root>
          <RequestDetails.Item title="Protocolo">{protocolo || ''}</RequestDetails.Item>
          <RequestDetails.Item title="Tipo">{appointment.type}</RequestDetails.Item>
          <RequestDetails.Item title="Especialidade">{appointment.specialty}</RequestDetails.Item>
          <RequestDetails.Item title="Para quem">{appointment.patient}</RequestDetails.Item>
          <RequestDetails.Item title="Meu local">{appointment.patientAddress}</RequestDetails.Item>
          <RequestDetails.Item title="Data de solicitação">
            {new Intl.DateTimeFormat('pt-BR', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            }).format(appointment.createdAt)}
          </RequestDetails.Item>

          <>
            {appointment.steps.map(({ status, title, address, date, cancelReason }, index) => (
              <RequestDetails.Step title={title} key={`step_${index}`}>
                <RequestDetails.StepItem title="Status">{status}</RequestDetails.StepItem>
                {cancelReason ? (
                  <RequestDetails.StepItem title="Motivo do encerramento">
                    {cancelReason}
                  </RequestDetails.StepItem>
                ) : (
                  <></>
                )}
                {address ? (
                  <RequestDetails.StepItem title="Endreço">{address}</RequestDetails.StepItem>
                ) : (
                  <></>
                )}
                {date ? (
                  <RequestDetails.StepItem title="Dia e Horario">
                    {new Intl.DateTimeFormat('pt-BR', {
                      weekday: 'long',
                      day: '2-digit',
                      month: 'long',
                      year: 'numeric',
                    }).format(date)}
                  </RequestDetails.StepItem>
                ) : (
                  <></>
                )}
              </RequestDetails.Step>
            ))}
          </>
        </RequestDetails.Root>
      </Collapse>

      {appointment.status === 'in-progress' && (
        <Collapse title="Reagendar ou cancelar">
          <Typography variant="body2">
            Se precisar cancelar ou reagendar seu atendimento fale com nossa Central de Atendimento.
          </Typography>
        </Collapse>
      )}

      <AppointmentFooter status={appointment.status} />
    </PageWithCard>
  );
};
