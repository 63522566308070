import React from 'react';
import { Linking, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { Button, Typography } from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';

export const ContactCentral = () => {
  const theme = useTheme();
  const navigation = useNavigation();

  return (
    <>
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">Para utilizar o benefício, ligue para</Typography>
        <Typography variant="bodyHighlight2">a Central de Atendimento:</Typography>
      </View>
      {theme.isMobile ? (
        <Button
          icon={<CallIcon color={theme.colors.background} />}
          iconPosition="left"
          variant="primary"
          onPress={() => Linking.openURL('tel:08008368836')}>
          Ligar
        </Button>
      ) : (
        <View className="h-[106px] w-full items-center justify-center self-center rounded-lg bg-background-gray text-center">
          <View className="mb-4 flex-row items-center space-x-2">
            <CallIcon color={theme.colors.paragraph} />
            <Typography variant="body2">Telefone:</Typography>
          </View>
          <Typography variant="body1" color={theme.colors.paragraph}>
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              4000 1640
            </Typography>{' '}
            ou{' '}
            <Typography variant="subtitle" color={theme.colors.paragraph}>
              0800 836 8836
            </Typography>
          </Typography>
        </View>
      )}
    </>
  );
};
