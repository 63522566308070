import CalendarCheck from "./calendar-check.svg";
import CalendarDay from "./calendar-day.svg";
import Calendar from "./calendar.svg";
import ClipFile from "./clip-file.svg";
import Clock from "./clock.svg";
import CommentsQuestionCheck from "./comments-question-check.svg";
import CommentsQuestion from "./comments-question.svg";
import Comments from "./comments.svg";
import MemoCircleCheck from "./memo-circle-check.svg";
import Memo from "./memo.svg";
import TimeCheck from "./time-check.svg";
import TimeQuarterPast from "./time-quarter-past.svg";

export {
	Calendar,
	CalendarCheck,
	CalendarDay,
	ClipFile,
	Clock,
	Comments,
	CommentsQuestion,
	CommentsQuestionCheck,
	Memo,
	MemoCircleCheck,
	TimeCheck,
	TimeQuarterPast,
};
