import React from 'react';
import { CanProps } from './types';
import { useAuth } from '~/auth/legacy/useAuth';

export const Cant = ({ children, features, onix }: CanProps) => {
  const { checkIsEnabledFeature, onixCode } = useAuth();

  if (!onixCode) {
    return null;
  }

  let userCanSeeComponent: boolean = false;

  userCanSeeComponent = features ? checkIsEnabledFeature(features) : userCanSeeComponent;
  userCanSeeComponent = onix ? onix.includes(onixCode.onix_code) : userCanSeeComponent;

  if (userCanSeeComponent) return null;

  return <>{children}</>;
};