import React, { type ReactElement } from 'react';
import { View } from 'react-native';

import { useTheme } from 'styled-components/native';

import { Typography } from '~/components/@hello-ui';

type RequestDetailsStep = {
  title: string;
  children: ReactElement | ReactElement[];
};

export const RequestDetailsStep = ({ title, children }: RequestDetailsStep) => {
  const theme = useTheme();

  return (
    <View className="mb-8 rounded-lg border" style={{ borderColor: theme.colors['gray-disabled'] }}>
      <View className="p-16">
        <Typography variant="body2" className="mb-8" style={{ color: theme.colors.placeholder }}>
          Etapa
        </Typography>
        <Typography variant="body2" style={{ color: theme.colors.paragraph }}>
          {title}
        </Typography>
      </View>
      {children}
    </View>
  );
};
