import React from 'react';
import Svg, { ClipPath, Defs, G, Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const CaregiverIcon = ({ width = 24, height = 24, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none">
      <G clipPath="url(#clip0_17589_50276)">
        <Path
          d="M6 2.5C6 1.119 7.119 0 8.5 0C9.881 0 11 1.119 11 2.5C11 3.881 9.881 5 8.5 5C7.119 5 6 3.881 6 2.5ZM5.716 9.198C7.224 7.653 9.777 7.653 11.284 9.198C11.48 9.399 11.74 9.5 12 9.5C12.252 9.5 12.504 9.405 12.698 9.216C13.094 8.83 13.101 8.197 12.716 7.802C11.582 6.64 10.085 6 8.5 6C6.915 6 5.418 6.64 4.284 7.802C3.898 8.198 3.906 8.83 4.302 9.216C4.697 9.601 5.33 9.595 5.716 9.198ZM23.236 13.015L16.432 20.652C14.536 22.78 11.814 24 8.965 24H4C1.794 24 0 22.206 0 20V15C0 12.794 1.794 11 4 11H12.857C13.997 11 14.996 11.609 15.546 12.519L18.763 8.985C19.304 8.39 20.044 8.042 20.848 8.004C21.642 7.974 22.421 8.243 23.017 8.786C24.231 9.892 24.329 11.79 23.235 13.016L23.236 13.015ZM21.671 10.263C21.472 10.081 21.212 9.981 20.943 10C20.673 10.013 20.424 10.13 20.243 10.329L15.817 15.194C15.432 16.264 14.478 17.084 13.303 17.252L8.142 17.989C7.591 18.063 7.088 17.687 7.01 17.14C6.932 16.593 7.312 16.086 7.859 16.008L13.02 15.271C13.58 15.192 14 14.706 14 14.141C14 13.511 13.487 12.998 12.857 12.998H4C2.897 12.998 2 13.895 2 14.998V19.998C2 21.101 2.897 21.998 4 21.998H8.965C11.244 21.998 13.421 21.021 14.939 19.32L21.743 11.682C22.111 11.27 22.079 10.632 21.671 10.261V10.263Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_17589_50276">
          <Rect width="24" height="24" fill={color ?? theme.colors.primary} />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default CaregiverIcon;
