import React from 'react';
import { usePageWithCardSettings, useStepper } from '~/components/@tem-ui/Stepper';
import { useAppointment } from '~/hooks/appointment';
import { SelectLocal } from '~/components/SelectLocal';
import { useCheckupContext } from '~/contexts/checkup';

export const ScheduleLocal = (): JSX.Element => {
  const { nextStep } = useStepper();
  const { appointment, setAppointment } = useAppointment();
  const { specialties } = useCheckupContext();

  usePageWithCardSettings({ scrollEnabled: true });

  return (
    <SelectLocal
      title="Local de atendimento"
      subtitle="Preencha automaticamente através do botão abaixo ou selecione manualmente"
      onConfirmedLocation={({ state, city, neighborhood }) => {
        const neighborhoodNames = [];

        if (neighborhood) {
          for (const n of neighborhood) {
            neighborhoodNames?.push(n.title);
          }
        }

        const address = {
          state: state?.title ?? '',
          city: city?.title ?? '',
          neighborhood: neighborhoodNames,
          lat: neighborhood[0]?.latitude,
          long: neighborhood[0]?.latitude,
        };

        const specialty = {
          id: specialties[0]?.id,
          name: specialties[0]?.name,
        };

        setAppointment({
          ...appointment,
          address,
          specialty: specialty,
        });
        nextStep();
      }}
    />
  );
};
