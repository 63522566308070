import { DefaultTheme } from 'styled-components/native';

export const makeTextByStatus = (theme: DefaultTheme) => ({
  requested: {
    text: 'Aguardar opções',
    color: theme.colors.paragraph,
  },
  scheduling: {
    text: 'Aguardar agendamento',
    color: theme.colors.paragraph,
  },
  accepted: {
    text: 'Agendado',
    color: theme.colors.paragraph,
  },
  finished: {
    text: 'Atendimento realizado',
    color: theme.colors.paragraph,
  },
  canceled: {
    text: 'Atendimento cancelado',
    color: theme.colors.paragraph,
  },
  suggested: {
    text: 'Escolher local',
    color: theme.colors.paragraph,
  },
  bmgSchedule: {
    text: 'Atendimento agendado',
    color: theme.colors.paragraph,
  },
});

export const makeTextByStage = (theme: DefaultTheme) => ({
  appointment: {
    text: 'Consulta',
    color: theme.colors.paragraph,
  },
  exam: {
    text: 'Exame',
    color: theme.colors.paragraph,
  },
  recurrence: {
    text: 'Retorno',
    color: theme.colors.paragraph,
  },
});
