import { OlimpoServiceClass } from '~/services/api/olimpo/olimpo-service-class';
import {
  IAuthenticateCertificateFullParams,
  IAuthenticateCertificateFullRes,
} from '~/services/api/olimpo/interfaces/sso-certificate-full-domain';

export class SSOCertificateFullDomain {
  private readonly olimpoService: OlimpoServiceClass;

  constructor(olimpoService: OlimpoServiceClass) {
    this.olimpoService = olimpoService;
  }

  async authenticateCertificate(
    params: IAuthenticateCertificateFullParams,
  ): Promise<IAuthenticateCertificateFullRes> {
    const { isInternal, encryptedData, sign, } = params;

    const { data } = await this.olimpoService.axios.post<IAuthenticateCertificateFullRes>(
      '/v1/hello/sso_certificate/icatu/authenticate',
      {},
      {
        headers: {
          Authorization: `${encryptedData}.${sign}`,
          'Content-Type': 'application/json',
          'x-internal-certificate': isInternal ? 'true' : 'false',
        },
      },
    );

    return data;
  }
}
  