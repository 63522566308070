import React from 'react';

import { useNavigation } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import desktopBackground from '../../../assets/images/SeniorAssistanceCaregiver/desktop.png';
import mobileBackground from '../../../assets/images/SeniorAssistanceCaregiver/mobile.png';
import { SeniorAssistanceContactMethods } from '../components/ContactMethods';
import { Collapse, MobileAutoSpace, Typography } from '~/components/@hello-ui';
import { PageWithCard } from '~/components/@hello-ui/PageWithCard';
import { PageWithCardVariant } from '~/components/@hello-ui/PageWithCard/enums/PageWithCardVariant';
import CaregiverIcon from '~/components/Icons/Caregiver';
import { goBack } from '~/helpers';

export const SeniorAssistanceCaregiver = (): JSX.Element => {
  const theme = useTheme();
  const navigation = useNavigation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const backgroundImage = Platform.OS === 'web' ? desktopBackground : mobileBackground;

  return (
    <PageWithCard
      variant={PageWithCardVariant.FeaturedImage}
      featuredImageOptions={{
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        backgroundImage,
        titleStartContent: <CaregiverIcon color={theme.colors.white} />,
        title: 'Cuidador',
        onBack: () => {
          goBack(navigation, 'BenefitsSeniorAssistance');
        },
      }}>
      <View>
        <Typography variant="body2">
          Cuidado especializado e atenção diária para idosos, com profissionais preparados para
          garantir conforto, segurança e apoio na rotina.
        </Typography>
      </View>
      <View className="pt-24">
        <Collapse title="Saiba mais">
          <Typography variant="body2">
            O serviço oferece suporte para o público sênior que precisa de acompanhamento em casa,
            proporcionando segurança e qualidade de vida.
          </Typography>
          <View className="mb-8 mt-8">
            <Typography variant="body2">
              Um cuidador capacitado estará presente para auxiliar em atividades do dia a dia, como
              alimentação, mobilidade, higiene e organização da rotina. Esse serviço é ideal para
              períodos específicos, oferecendo a atenção necessária com toda a empatia e
              profissionalismo.
            </Typography>
          </View>

          <View className=" mt-8">
            <Typography variant="body2">
              <Typography variant="bodyHighlight2">IMPORTANTE:</Typography> Você tem direito a até
              10 dias por ano.
            </Typography>
          </View>
        </Collapse>
        <Collapse title="Como utilizar">
          <Typography variant="body2">
            Agende o serviço informando os dias desejados e a rotina que o cuidador deve seguir. Um
            profissional estará à disposição no horário combinado para oferecer o melhor cuidado
            dentro de casa.
          </Typography>
        </Collapse>
      </View>

      <MobileAutoSpace />
      <SeniorAssistanceContactMethods />
    </PageWithCard>
  );
};
