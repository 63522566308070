import clsx from 'clsx';
import React from 'react';
import { useTheme } from 'styled-components/native';
import { Typography } from '~/components/@hello-ui';

type ProgressTitleProps = {
  children: string;
  active?: boolean;
  color?: string;
};

export const ProgressTitle = ({ children, active, color }: ProgressTitleProps) => {
  const theme = useTheme();

  return (
    <Typography
      variant="titleXsmall"
      className={clsx('mb-4 text-center text-sm', { 'font-normal': !active })}
      color={color || theme.colors['gray-disabled']}>
      {children}
    </Typography>
  );
};
