import React from 'react';

import { Linking, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Button, Typography } from '~/components/@hello-ui';
import { CallIcon } from '~/components/Icons/Call';

export const SeniorAssistanceContactMethods = (): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <View className="mb-24 mt-40 w-full items-center self-center text-center mobile:mb-16 mobile:mt-24">
        <Typography variant="bodyHighlight2">
          Para acionamento ou dúvidas, ligue para a Central de Atendimento:
        </Typography>
      </View>

      {theme.isMobile ? (
        <View className="w-full">
          <View className="mt-16 w-full items-center self-center text-center">
            <Typography variant="body2">Para capitais e regiões metropolitanas</Typography>
            <Button
              className="w-full"
              icon={<CallIcon color={theme.colors.background} />}
              iconPosition="left"
              variant="primary"
              onPress={() => Linking.openURL('tel:1140001640')}>
              (11) 4000-1640
            </Button>
          </View>
          <View className="mt-16 w-full items-center self-center text-center">
            <Typography variant="body2">Para demais regiões</Typography>
            <Button
              icon={<CallIcon color={theme.colors.primary} />}
              iconPosition="left"
              variant="outline"
              onPress={() => Linking.openURL('tel:08008368836')}>
              0800 836 8836
            </Button>
          </View>
          <View className="mt-16 w-full items-center self-center text-center">
            <Typography variant="body1">Segunda à Sexta das 7h às 19h</Typography>
            <Typography variant="body2">Exceto feriados nacionais</Typography>
          </View>
        </View>
      ) : (
        <View className="flex h-[210px] w-full rounded-lg bg-background-gray pb-24 pl-32 pr-32 pt-24 text-center">
          <View className="flex w-full flex-row">
            <View className="basis-1/2 text-left">
              <View className="w-full">
                <Typography variant="body1" color={theme.colors.primary}>
                  (11) 4000-1640
                </Typography>
                <Typography variant="body2">Capitais e regiões metropolitanas:</Typography>
              </View>
            </View>
            <View className="basis-1/2 text-left">
              <Typography variant="body1" color={theme.colors.primary}>
                0800 836 88 36
              </Typography>
              <Typography variant="body2">Demais localidades</Typography>
            </View>
          </View>
          <View className="mt-24 w-full text-left">
            <Typography variant="body1">Segunda à Sexta das 7h às 19h</Typography>
            <Typography variant="body2">Exceto feriados nacionais</Typography>
          </View>
        </View>
      )}
    </>
  );
};
