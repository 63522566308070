import React from 'react';
import Svg, { Path, G } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from './types';

const ScalpelIcon = ({ width, height, color }: IconProps): JSX.Element => {
  const theme = useTheme();

  return (
    <Svg width={width ?? 24} height={height ?? 24} viewBox="0 0 24 24" fill="none">
      <G id="icon-custom">
        <Path
          d="m23.124.873c-1.134-1.133-3.11-1.133-4.243,0L.295,19.459c-.15.15-.249.344-.281.554-.058.371.014.923.387,1.357.244.285.706.624,1.524.624.474,0,.879.009,1.116,0,1.8-.071,3.552-.479,5.208-1.211,2.591-1.146,4.371-3.202,5.161-5.955l9.712-9.712c1.17-1.17,1.17-3.072,0-4.242ZM7.441,18.953c-1.425.63-2.932.98-4.479,1.042-.191.008-.339.008-.381.006l7.034-7.033,1.769,1.65c-.668,2.016-1.993,3.473-3.942,4.335ZM21.709,3.701l-9.216,9.216-1.463-1.364L20.296,2.287c.377-.379,1.036-.379,1.414,0,.39.39.39,1.024,0,1.414Zm-3.709,19.299c0,.553-.448,1-1,1h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Zm-6,0c0,.553-.448,1-1,1h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Zm12,0c0,.553-.448,1-1,1h-2c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1Z"
          fill={color ?? theme.colors.primary}
        />
      </G>
    </Svg>
  );
};

export default ScalpelIcon;
