import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useTheme } from 'styled-components/native';
import { View } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import parse from 'date-fns/parse';
import { format } from 'date-fns';
import {
  Button,
  Input,
  MobileAutoSpace,
  PageWithCard,
  RadioBoxGroup,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { mapDependent } from '~/helpers';
import { trackingEventType } from '~/contexts/tracking/types';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { useTracking } from '~/services/tracking';
import {
  BIRTHDATE_MASK,
  EMAIL_MASK,
  NAME_CANNOT_CONTAIN_ONLY_BLANKS,
  TELPHONE_MASK,
} from '~/helpers/mask-regex-patterns';
import { isBirthdateValid, validatePhone } from '~/utils/validations';
import { toCamelCase } from '~/utils/strings';
import { AuthService } from '~/auth/auth-service';

import { NavigationProps } from '~/@types/navigation/routes-helpers';

const schema = yup.object().shape({
  name: yup
    .string()
    .required('Digite um nome')
    .matches(NAME_CANNOT_CONTAIN_ONLY_BLANKS, 'Nome não pode conter apenas espaços em branco'),
  birthdate: yup
    .string()
    .matches(BIRTHDATE_MASK, 'Digite uma data válida')
    .test({
      test: (value = '') => isBirthdateValid(value),
      message: 'Digite uma data válida',
    })
    .required('Digite uma data'),
  Telefone: yup
    .string()
    .matches(TELPHONE_MASK, 'Digite um número válido')
    .required('Digite um número de celular'),
  email: yup.string().matches(EMAIL_MASK, 'Digite um e-mail válido').required('Digite um e-mail'),
});

interface EditDependentsProps extends NavigationProps<'EditDependents'> {}

export const EditDependents: React.FC<EditDependentsProps> = ({ route }) => {
  const theme = useTheme();
  const { params } = route;
  const navigation = useNavigation();
  const api = useApi();
  const { user, dependents } = useAuth();
  const { tracking } = useTracking();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isValid },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      sexo: 'FEMININO',
      cpf: '',
      cpfTitular: user?.cpf,
      birthdate: '',
      Telefone: '',
      email: '',
    },
  });

  const [loading, setLoading] = useState(false);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();

  useEffect(() => {
    if (dependents) {
      const dependent = dependents.find((d: any) => {
        return d.cpf === params?.cpf;
      });

      const birthdate = dependent?.birthdate
        ? format(parse(dependent?.birthdate, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')
        : '';

      if (dependent.phone === '11999999999') {
        dependent.phone = '';
      }

      if (dependent?.name) {
        setValue('name', toCamelCase(dependent?.name), { shouldValidate: !!dependent?.name });
      }
      setValue('cpf', dependent?.cpf, { shouldValidate: !!dependent?.cpf });
      setValue('Telefone', dependent?.phone, { shouldValidate: !!dependent?.phone });
      setValue('birthdate', birthdate, {
        shouldValidate: !!birthdate,
      });
      setValue('sexo', dependent?.gender, {
        shouldValidate: !!dependent?.gender,
      });
      setValue('email', dependent?.email);
    }
  }, [dependents]);

  const handleForm = async (values: any) => {
    if (!validatePhone(values.Telefone)) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Telefone inválido, informe um número válido',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } else {
      setLoading(true);
      try {
        const dependent = dependents.find((d: any) => {
          return d.cpf === params?.cpf;
        });
        await api.updateDependent({ ...values, cpf: dependent?.cpf });

        await AuthService.updateDependents();

        showSweetAlert('Tudo certo!', 'Os dados foram atualizados', 'success', false, false, {
          layout: 'helloUi',
          buttons: [
            {
              text: 'Ok',
              variant: 'primary',
              onPress: () => {
                hideSweetAlert();
                navigation.navigate('Dependents');
              },
            },
          ],
        });

        void tracking.addMovement({
          event: trackingEventType.UpdatedDependent,
        });
      } catch (e) {
        showSweetAlert('Ops, algo deu errado', 'Erro ao editar', 'error', false, false, {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        });

        await tracking.addMovement({
          event: trackingEventType.UpdatedDependent,
          metadata: { error: { message: 'Erro ao editar', reason: e.message } },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Atualizar Dependentes',
        onBack: () => navigation.navigate('DependentsView', { cpf: params?.cpf }),
      }}>
      <Typography variant="subtitle" className="mb-24 mobile:mb-16">
        Dados Pessoais
      </Typography>
      <View className="mb-8">
        <Controller
          name="name"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={(event) => {
                onChange(
                  event === ' '
                    ? event.replace(/( ){1,}/g, '')
                    : event.replace(/[0-9]/g, '').replace(/( ){2,}/g, ''),
                );
              }}
              placeholder="Digite"
              label="Nome Completo"
              error={error?.message}
            />
          )}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="cpf"
          control={control}
          defaultValue=""
          disabled
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              isDisabled
              variant="mask"
              mask="999.999.999-99"
              onChangeText={onChange}
              placeholder="Digite"
              label="CPF"
              error={error?.message}
              keyboardType="numeric"
            />
          )}
        />
      </View>
      <Controller
        name="sexo"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <RadioBoxGroup
            data={[
              { label: 'Feminino', value: 'FEMININO' },
              { label: 'Masculino', value: 'MASCULINO' },
              // { label: 'Prefiro não informar', value: 'INDIFERENTE' },
            ]}
            vertical={theme.isMobile}
            value={value}
            onChange={onChange}
            error={error?.message}
            label="Sexo Biológico"
          />
        )}
      />

      <View className="mb-8">
        <Controller
          name="birthdate"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              mask="99/99/9999"
              variant="mask"
              placeholder="Digite"
              label="Data de Nascimento"
              error={error?.message}
              keyboardType="numeric"
              onSubmitEditing={handleSubmit(handleForm)}
            />
          )}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              value={value}
              onChangeText={onChange}
              placeholder="Digite um e-mail"
              label="E-mail"
              error={error?.message}
              onSubmitEditing={handleSubmit(handleForm)}
            />
          )}
        />
      </View>

      <Controller
        name="Telefone"
        control={control}
        defaultValue=""
        rules={{ required: true }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Input
            value={value}
            mask="(99) 9 9999-9999"
            onChangeText={onChange}
            variant="mask"
            placeholder="Digite"
            label="Celular"
            error={error?.message}
            keyboardType="phone-pad"
            onSubmitEditing={handleSubmit(handleForm)}
          />
        )}
      />
      <MobileAutoSpace heightMobile={40} heightDesktop={40} />
      <Button
        variant="primary"
        onPress={handleSubmit(handleForm)}
        loading={loading}
        disabled={loading || !isValid}>
        Atualizar
      </Button>
    </PageWithCard>
  );
};
