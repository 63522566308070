import React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

type IconProps = {
  width: number;
  height: number;
};

export const FileMedicalIcon = ({ width, height }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 48 48" fill="none">
      <Circle cx={24} cy={24} r={24} fill="#F5F5F5" />
      <Path
        d="M16.667 29.333H9.333a1.334 1.334 0 010-2.666h6.62l2.271-3.407c.263-.395.723-.63 1.192-.59.475.029.897.309 1.11.734l2.973 5.945 1.392-2.088c.248-.37.664-.593 1.109-.593h6a1.334 1.334 0 010 2.667h-5.287l-2.27 3.406a1.334 1.334 0 01-1.11.594c-.028 0-.056 0-.082-.003a1.335 1.335 0 01-1.111-.735l-2.972-5.945-1.392 2.088c-.248.37-.664.593-1.11.593zm20.666-7.353v11.353A6.674 6.674 0 0130.667 40H17.333a6.674 6.674 0 01-6.666-6.667 1.334 1.334 0 012.666 0c0 2.206 1.795 4 4 4h13.334c2.205 0 4-1.794 4-4V21.98c0-.217-.011-.433-.031-.647H28c-2.205 0-4-1.794-4-4v-6.636a6.93 6.93 0 00-.647-.03h-6.02c-2.205 0-4 1.794-4 4v8a1.334 1.334 0 01-2.666 0v-8A6.674 6.674 0 0117.333 8h6.02c2.494 0 4.836.97 6.6 2.733L34.6 15.38a9.273 9.273 0 012.733 6.6zm-3.545-3.313a6.671 6.671 0 00-1.073-1.4l-4.647-4.647a6.707 6.707 0 00-1.4-1.073v5.788c0 .734.599 1.333 1.333 1.333h5.788l-.001-.001z"
        fill="#EC6338"
      />
    </Svg>
  );
};
