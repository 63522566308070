import React from 'react';

import { View } from 'react-native';

import { useTheme } from 'styled-components/native';

import { Typography } from '~/components/@hello-ui';

type RequestDetailsItemProps = {
  children: string;
  title: string;
};

export const RequestDetailsItem = ({ children, title }: RequestDetailsItemProps) => {
  const theme = useTheme();

  return (
    <View className="mb-8">
      <Typography variant="body2" style={{ color: theme.colors.placeholder }}>
        {title}
      </Typography>
      <Typography variant="body2" style={{ color: theme.colors.paragraph }}>
        {children}
      </Typography>
    </View>
  );
};
