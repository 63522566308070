import React from 'react';
import VideoIcon from '~/components/Icons/Video';
import { StyledDefaultTheme } from '~/components/@hello-ui';
import StethoscopeIcon from '~/components/Icons/Stethoscope';
import ExamIcon from '~/components/Icons/Exam';
import PsychologyIcon from '~/components/Icons/Psychology';
import MedicalCheckupIcon from '~/components/Icons/MedicalCheckupIcon';

export const ICON_BY_TYPE = {
  online: (
    <VideoIcon
      width={StyledDefaultTheme.isMobile ? 24 : 28}
      height={StyledDefaultTheme.isMobile ? 24 : 28}
      color={StyledDefaultTheme.colors.white}
    />
  ),
  presential: (
    <StethoscopeIcon
      width={StyledDefaultTheme.isMobile ? 24 : 28}
      height={StyledDefaultTheme.isMobile ? 24 : 28}
      color={StyledDefaultTheme.colors.white}
    />
  ),
  exam: (
    <ExamIcon
      width={StyledDefaultTheme.isMobile ? 24 : 28}
      height={StyledDefaultTheme.isMobile ? 24 : 28}
      color={StyledDefaultTheme.colors.white}
    />
  ),
  checkup: (
    <MedicalCheckupIcon
      color={StyledDefaultTheme.colors.white}
      width={StyledDefaultTheme.isMobile ? 24 : 28}
      height={StyledDefaultTheme.isMobile ? 24 : 28}
    />
  ),
  therapy: (
    <PsychologyIcon
      width={StyledDefaultTheme.isMobile ? 24 : 28}
      height={StyledDefaultTheme.isMobile ? 24 : 28}
      color={StyledDefaultTheme.colors.white}
    />
  ),
};
