import React, { type ReactElement } from 'react';

import { View } from 'react-native';
import { useTheme } from 'styled-components/native';

type RequestDetailsRootProps = {
  children: ReactElement | ReactElement[];
};

export const RequestDetailsRoot = ({ children }: RequestDetailsRootProps) => {
  const theme = useTheme();

  return (
    <View className="border-px rounded-lg" style={{ borderColor: theme.colors['gray-disabled'] }}>
      {children}
    </View>
  );
};
