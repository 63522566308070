import { useFocusEffect } from '@react-navigation/native';
import React, { useCallback, useEffect, useState } from 'react';
import { useAuth } from '~/auth/legacy/useAuth';
import { useStepper } from '~/components/@tem-ui/Stepper';
import { useCheckupContext } from '~/contexts/checkup';
import { trackingEventType } from '~/contexts/tracking/types';
import { TypesEnum } from '~/enums/appointment';
import { ScheduleLocal } from '~/screens/ScheduleAppointment/components/ScheduleLocal';
import { ScheduleReview } from '~/screens/ScheduleAppointment/components/ScheduleReview';
import { ScheduleSpecialty } from '~/screens/ScheduleAppointment/components/ScheduleSpecialty';
import { SelectDependents } from '~/screens/ScheduleAppointment/components/SelectDependents/SelectDependents';
import { ScheduleSuccess } from '~/screens/ScheduleSuccess';

export const scheduleCheckup = () => {
  const { toStepper } = useStepper();
  const [availableForDependents, setAvailableForDependents] = useState<boolean>(true);
  const { checkIsEnabledFeature, dependents } = useAuth();
  const { specialties } = useCheckupContext();

  useFocusEffect(
    useCallback(() => {
      toStepper(0);
      setAvailableForDependents(
        checkIsEnabledFeature('checkup_familiar') &&
          Array.isArray(dependents) &&
          dependents.some((dependent) => dependent.status === 1),
      );
    }, []),
  );

  return [
    {
      visible: false,
      title: 'Check-up',
      visibilitySteps: false,
      disabled: true,
      icon: <></>,
      steps: [
        ...(specialties.length > 1
          ? [<ScheduleSpecialty isCheckup={true} key="ScheduleSpecialty" />]
          : []),
        <ScheduleLocal key="ScheduleLocal" />,
        ...(availableForDependents ? [<SelectDependents key="SelectDependents" />] : []),
        <ScheduleReview
          key="ScheduleReview"
          specialtyName="checkup"
          scheduleType={TypesEnum.CheckUp}
          trackingType={trackingEventType.RequestedInDentalConsultationsAndProcedures}
          redirect
        />,
        <ScheduleSuccess key="ScheduleSuccess" />,
      ],
    },
  ];
};
