import { useFocusEffect } from '@react-navigation/native';
import { clsx } from 'clsx';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Dimensions, FlatList, Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { AppointmentProps, EListType } from '../types';
import { useAuth } from '~/auth/legacy/useAuth';
import { Loader, Typography } from '~/components/@hello-ui';
import { PageWithCardContext } from '~/components/@hello-ui/PageWithCard/context/PageWithCardContext';
import { useApi } from '~/hooks/api';
import { useStatusBarHeight } from '~/hooks/useStatusBarHeight';
import {
  AppointmentItem,
  AppointmentItemType,
} from '~/screens/MyAppointments/components/AppointmentList/AppointmentItem';
import { Tab } from '~/screens/MyAppointments/components/Tab/Tab';
import { Appointment } from '~/screens/MyAppointments/state/types';
import { isMercadoPagoOnix } from '~/helpers/mercado-pago';

export const AppointmentList = (): JSX.Element => {
  const api = useApi();
  const { headerHeight } = useContext(PageWithCardContext);
  const statusBarHeight = useStatusBarHeight();
  const { user, onixCode } = useAuth();
  const theme = useTheme();

  const [tab, setTab] = useState<EListType>(EListType.Next);
  const [loaded, setLoaded] = useState({ next: false, history: false });

  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [pageAppointments, setPageAppointments] = useState(1);

  const [history, setHistory] = useState<Appointment[]>([]);
  const [pageHistory, setPageHistory] = useState(1);

  const isEmpty = tab === EListType.Next ? appointments.length === 0 : history.length === 0;

  const isFetching = useRef(true);

  useEffect(() => {
    if (pageAppointments) {
      void loadAppointments({ page: pageAppointments, finished: false });
    }
  }, [pageAppointments]);

  useEffect(() => {
    if (pageHistory) {
      void loadAppointments({ page: pageHistory, finished: true });
    }
  }, [pageHistory]);

  useFocusEffect(
    useCallback(() => {
      reset();
    }, []),
  );

  const reset = () => {
    setAppointments([]);
    setHistory([]);
    setPageAppointments(1);
    setPageHistory(1);
    setTab(EListType.Next);
    setLoaded({ next: false, history: false });
  };

  const loadAppointments = async ({ page, finished }: AppointmentProps) => {
    console.log('🚀 ~ loadAppointments ~ page:', page);
    try {
      isFetching.current = true;
      const { data } = await api.getMyAppointments({
        cpf: user?.cpf ?? '',
        page: page,
        perPage: 10,
        finished: finished,
      });

      const withoutCheckupOnMP =
        data?.data
          .filter((item: AppointmentItemType) => {
            if (isMercadoPagoOnix(onixCode?.onixCode) && item.type === 'checkup') {
              return false;
            }
            return true;
          })
          .sort((a, b) => a.specialty?.name?.localeCompare(b.specialty?.name)) ?? [];
      const dataFiltered = filterAppointments(withoutCheckupOnMP);
      if (finished) {
        setHistory((oldHistory) => [...oldHistory, ...dataFiltered]);
      } else {
        setAppointments((oldAppointments) => [...oldAppointments, ...dataFiltered]);
      }
    } finally {
      isFetching.current = false;
      setLoaded((last) => ({ ...last, [finished ? 'history' : 'next']: true }));
    }
  };

  /** Aqui estou filtrando:
   *  dependentes menores de 18 anos;
   *  titulares;
   * e ocultando os dependentes maiores de 18anos. */
  const filterAppointments = (data: Appointment[]) => {
    const today = new Date();
    const appointmentsFiltered = data.filter((appointment) => {
      const birthDate = new Date(appointment.patient?.birthDate ?? '');
      const age = today.getFullYear() - birthDate.getFullYear();
      const isBeforeBirthday =
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate());
      const isUnderage = age < 18 || (age === 18 && isBeforeBirthday);
      const isHolder = appointment.patient?.holder;
      return (isUnderage && !isHolder) || (!isUnderage && isHolder);
    });
    return appointmentsFiltered;
  };

  const fetchMore = async () => {
    if (isFetching.current) {
      return;
    }

    if (tab === EListType.Next) {
      setPageAppointments((page) => page + 1);
    } else {
      setPageHistory((page) => page + 1);
    }
  };

  const changeTab = (type: EListType) => {
    setTab(type);
  };

  const EmptyComponent = () => {
    return (
      <Typography variant="body2" style={{ textAlign: 'center' }}>
        <Typography variant="bodyHighlight2">Você não possui agendamentos</Typography>
        {'\n'}
        Aproveite para começar a cuidar da saúde!
      </Typography>
    );
  };

  const renderItem = ({ item, index }: { item: Appointment; index: number }) => {
    return (
      <View
        key={index}
        className={clsx('w-full max-w-[530px]', index !== 0 && 'mt-16 mobile:mt-8')}>
        <AppointmentItem {...item} cpf={user?.cpf ?? ''} />
      </View>
    );
  };

  return (
    <View
      className="pt-24 tablet:pt-40 desktop:pt-40"
      style={{
        height:
          Dimensions.get('window').height -
          headerHeight -
          statusBarHeight -
          (theme.isMobile ? 0 : 90) -
          (Platform.OS === 'ios' ? 40 : 0),
      }}>
      <View className="mb-20 px-16 mobile:mb-12 tablet:px-64 desktop:px-92">
        <View className="w-full flex-row">
          <Tab active={tab === EListType.Next} onPress={() => changeTab(EListType.Next)}>
            Próximos
          </Tab>
          <Tab active={tab === EListType.History} onPress={() => changeTab(EListType.History)}>
            Histórico
          </Tab>
        </View>
      </View>
      {!loaded.history || !loaded.next ? (
        <View className="flex-1 px-16 tablet:px-64 desktop:px-92">
          <View className="w-full max-w-[530px] flex-1">
            <Loader variant="circle-loader" />
          </View>
        </View>
      ) : (
        <FlatList
          data={tab === EListType.Next ? appointments : history}
          key={tab}
          renderItem={renderItem}
          ListEmptyComponent={EmptyComponent}
          keyExtractor={(item, idx) => idx.toString()}
          contentContainerStyle={{
            paddingBottom: theme.isMobile ? 16 : theme.isDesktop ? 40 : 24,
            paddingTop: theme.isMobile ? 12 : isEmpty ? 4 : 20,
            paddingHorizontal: theme.isMobile ? 16 : theme.isDesktop ? 92 : 64,
          }}
          showsVerticalScrollIndicator={false}
          onEndReached={fetchMore}
        />
      )}
    </View>
  );
};
