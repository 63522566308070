import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { View, ScrollView, TouchableOpacity, Dimensions } from 'react-native';
import { useTheme } from 'styled-components/native';
import Icon from 'react-native-vector-icons/dist/MaterialIcons';
import { DrawerItem } from '@react-navigation/drawer';
import { Divider } from '~/components/@hello-ui/Divider';
import { Typography } from '~/components/@hello-ui/Typography';
import { Logo } from '~/components/Logo';
import MenuIcon from '~/components/Icons/Menu';
import ProfileIcon from '~/components/Icons/Profile';
import MyCardsIcon from '~/components/Icons/MyCards';
import RegulationsIcon from '~/components/Icons/Regulations';
import SchedulesIcon from '~/components/Icons/Schedules';
import RechargeIcon from '~/components/Icons/Recharge';
import HealthCardIcon from '~/components/Icons/HealthCard';
import ExitIcon from '~/components/Icons/Exit';
import { useMyAppointments } from '~/screens/MyAppointments/state/useMyAppointments';
import { useAuth } from '~/auth/legacy/useAuth';
import { useLogin } from '~/screens/Login/state/useLogin';
import { trackingEventType } from '~/contexts/tracking/types';
import { useTracking } from '~/services/tracking';
import { AuthService } from '~/auth/auth-service';

interface Item {
  title: string;
  onPress?: () => void;
  icon: JSX.Element;
  isSubitem?: boolean;
  tracking?: trackingEventType;
}

interface MenuItem extends Item {
  index: number;
  visible: boolean;
  submenu?: {
    status: boolean;
    items: Item[];
  };
}

interface SidebarProps {
  onClose?: () => void;
}

export const Sidebar = ({ onClose }: SidebarProps) => {
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<Record<string, unknown>>>();

  const { tracking } = useTracking();
  const { checkIsEnabledFeature } = useAuth();

  const goTo = (screen?: string) => {
    onClose && onClose();
    closeAllMenuItems();
    screen && navigation.navigate(screen);
  };

  const closeAllMenuItems = () => {
    const updatedMenu = [...menu];
    updatedMenu.forEach((menu) => {
      menu.submenu.status = false;
    });

    setMenu(updatedMenu);
  };

  const [menu, setMenu] = useState(
    useMemo(
      () => [
        {
          title: 'Menu',
          orientation: -6,
          icon: <MenuIcon />,
          visible: true,
          submenu: {
            status: false,
            items: [
              {
                title: 'Perfil',
                icon: <ProfileIcon />,
                onPress: () => goTo('Profile'),
                tracking: trackingEventType.ClickedOnMyProfile,
                visible: true,
              },
              {
                title: 'Meus Cartões',
                icon: <MyCardsIcon />,
                onPress: () => goTo('MyCreditCards'),
                tracking: trackingEventType.ClickedOnMyCards,
                visible: !checkIsEnabledFeature('hide_recharge'),
              },
              {
                title: 'Regulamentos e Privacidades',
                icon: <RegulationsIcon />,
                onPress: () => goTo('RegulationAndPrivacy'),
                tracking: trackingEventType.ClickedOnRegulation,
                visible: true,
              },
            ],
          },
        },
        {
          title: 'Meus agendamentos',
          icon: <SchedulesIcon />,
          visible: true,
          submenu: {
            status: false,
            items: [],
          },
          onPress: () => goTo('MyAppointments'),
          tracking: trackingEventType.ClickedOnMyAppointments,
        },
        {
          title: 'Recarga',
          icon: <RechargeIcon />,
          visible: !checkIsEnabledFeature('hide_recharge'),
          submenu: {
            status: false,
            items: [],
          },
          onPress: () => goTo('TemPayRecharge'),
          tracking: trackingEventType.ClickedOnRecharge,
        },
        {
          title: 'Conta saúde',
          icon: <HealthCardIcon />,
          visible: true,
          submenu: {
            status: false,
            items: [],
          },
          onPress: () => goTo('TemPayDashboard'),
          tracking: trackingEventType.ClickedOnMyHealthCard,
        },
      ],
      [navigation],
    ),
  );

  const [showSairInMenu, setShowSairInMenu] = useState(false);

  const toggleSubmenu = (index: number) => {
    const updatedMenu = [...menu];
    updatedMenu[index].submenu.status = !updatedMenu[index].submenu.status;
    setMenu(updatedMenu);
  };

  const scrollViewRef = useRef(null);

  const handleResize = () => {
    const scrollView = scrollViewRef.current;

    if (scrollView) {
      const { scrollHeight, clientHeight }: { scrollHeight: number; clientHeight: number } =
        scrollView;

      setShowSairInMenu(scrollHeight > clientHeight);
    }
  };

  useEffect(() => {
    closeAllMenuItems();
    handleResize();

    Dimensions.addEventListener('change', handleResize);

    return () => {
      Dimensions.removeEventListener('change', handleResize);
    };
  }, []);

  useEffect(() => {
    handleResize();
  }, [menu]);

  const renderIcon = (icon: JSX.Element, isSubitem = false) => {
    return <View className={`${isSubitem ? 'ml-32' : ''} mr-[-15px]`}>{icon}</View>;
  };

  const renderItem = ({ title, submenu }: MenuItem) => {
    if (theme.isMobile) {
      return (
        <View className="w-full flex-row content-center items-center justify-center">
          <View className={submenu?.items.length ? 'w-11/12' : 'w-full'}>
            <Typography variant="body2" color={theme.colors.black}>
              {title}
            </Typography>
          </View>
          {submenu?.items.length ? (
            <View className="pt-[4px] mobile:w-1/12">
              <Icon
                name={submenu?.status ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
                size={25}
                color={theme.colors.primary}
              />
            </View>
          ) : (
            <></>
          )}
        </View>
      );
    }

    return (
      <Typography variant="body2" color={theme.colors.black}>
        <>
          {title}
          {submenu?.items.length ? (
            <Icon
              name={submenu?.status ? 'keyboard-arrow-up' : 'keyboard-arrow-down'}
              size={25}
              style={{ position: 'absolute' }}
              color={theme.colors.primary}
            />
          ) : (
            <></>
          )}
        </>
      </Typography>
    );
  };

  const renderMenuItem = (menuItem: MenuItem) => {
    const { isSubitem, index, submenu, onPress, icon } = menuItem;

    // console.log('tesssss>>>', menuItem);

    return (
      <View key={`sidebar-${isSubitem ? 'sub' : ''}item-${index}`}>
        <DrawerItem
          style={{ width: '100%' }}
          label={() => {
            return renderItem({ ...menuItem });
          }}
          icon={() => renderIcon(icon, isSubitem)}
          onPress={() => {
            if (submenu?.items?.length) {
              toggleSubmenu(index);
            } else {
              if (menuItem?.tracking) {
                void tracking.addMovement({
                  event: menuItem.tracking,
                });
              }

              onPress && onPress();
            }
          }}
        />
        {theme.isMobile && <Divider />}
      </View>
    );
  };

  const exitMenu = renderMenuItem({
    title: 'Sair',
    icon: <ExitIcon />,
    visible: true,
    submenu: {
      status: false,
      items: [],
    },
    onPress: async () => {
      await AuthService.logout();
    },
    tracking: trackingEventType.Logout,
    index: 999,
  });

  return (
    <View className="justify-cente h-full flex-1 items-center">
      <TouchableOpacity
        className="h-48 w-full items-center justify-center pb-80 pt-100 mobile:hidden"
        onPress={() => navigation.navigate('Dashboard')}>
        {!theme.isMobile && <Logo height={56} width={120} />}
      </TouchableOpacity>

      {theme.isMobile && (
        <View className="mt-24 h-48 w-full items-end justify-end pr-24">
          <TouchableOpacity
            className="top-0 h-40 w-40 items-center justify-center rounded-full bg-background-gray target:hidden desktop:hidden"
            onPress={onClose}>
            <Icon name="close" size={24} color={theme.colors.black} />
          </TouchableOpacity>
        </View>
      )}

      {theme.isMobile && (
        <View className="mb-24 w-full">
          <Typography variant="h3" color={theme.colors.black} className="w-full pl-24 text-left">
            Menu
          </Typography>
        </View>
      )}

      <ScrollView className="h-full w-full max-w-[350px] mobile:max-w-full" ref={scrollViewRef}>
        <Divider />
        {menu.map(
          (item, index) =>
            item.visible && (
              <View key={`sidebar-menu-item-${index}`}>
                {renderMenuItem({ ...item, index })}
                {item.submenu?.status &&
                  item.submenu.items.map(
                    (subItem, idx) =>
                      subItem.visible &&
                      renderMenuItem({ ...subItem, index: idx, isSubitem: true }),
                  )}
              </View>
            ),
        )}
        {showSairInMenu && exitMenu}
      </ScrollView>

      <View className="w-full max-w-[350px] mobile:max-w-full">
        {!showSairInMenu && exitMenu}

        <View className="mb-24 ml-20 mt-24">
          <Typography variant="helperText" color={theme.colors.black20}>
            Powered by ©TEM Saúde
          </Typography>
        </View>
      </View>
    </View>
  );
};
