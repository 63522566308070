import { clsx } from 'clsx';
import React from 'react';

import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Typography } from '~/components/@hello-ui';

import { Footer } from '~/components/Footer';

type AppointmentFooterProps = {
  status?: 'in-progress' | 'closed' | 'completed';
};

export const AppointmentFooter = ({ status = 'in-progress' }: AppointmentFooterProps) => {
  const theme = useTheme();
  return (
    <View
      className={clsx('flex-1', {
        'justify-end': status === 'in-progress',
        'justify-between': status !== 'in-progress',
      })}>
      {status !== 'in-progress' && (
        <View className="py-24">
          <Typography variant="bodyHighlight2" className="mb-8">
            {status !== 'completed' ? 'Não se esqueça de se cuidar!' : 'Continue se cuidando!'}
          </Typography>

          <Typography variant="body2">
            {status !== 'completed'
              ? 'Conte com a gente para remarcar o seu atendimento.'
              : 'Conte com a gente se precisar de um novo atendimento, estaremos por aqui :)'}
          </Typography>
        </View>
      )}

      {!theme.isMobile && (
        <Typography variant="bodyHighlight2" className="mt-24 text-center">
          Dúvidas? Fale conosco:
        </Typography>
      )}

      <View className="mt-16 mobile:mt-24">
        <Footer
          variant="simple-phone-footer"
          showButtonOnMobile
          textButtonOnMobile="Dúvidas? Fale conosco:"
        />
      </View>
    </View>
  );
};
