import React, { useCallback, useEffect, useState } from 'react';
import { Linking, TouchableOpacity, View } from 'react-native';
import { Controller, useForm } from 'react-hook-form';
import { useNavigation, useRoute } from '@react-navigation/native';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LINK_TO_SEARCH_CEP } from '@env';
import { useTheme } from 'styled-components/native';
import {
  Button,
  Checkbox,
  Input,
  PageWithCard,
  Select,
  Typography,
  useAdresses,
  useSweetAlert,
} from '~/components/@hello-ui';
import { GeolocationButton } from '~/components/@hello-ui/Adresses/Geolocation/component/GeolocationButton';
import { SelectItem } from '~/components/@hello-ui/Select/types';
import { useGeolocation } from '~/components/@hello-ui/Adresses/Geolocation';
import { useAuth } from '~/auth/legacy/useAuth';
import { capitalize, extractDigits } from '~/utils/strings';
import { useApi } from '~/hooks/api';
import { AuthService } from '~/auth/auth-service';
import { UseRoute } from '~/@types/navigation/routes-helpers';

const schema = yup.object().shape({
  email: yup.string().email('Digite um email válido').optional(),
  cep: yup.string().required('Campo obrigatório'),
  street: yup.string().required('Campo obrigatório'),
  neighborhood: yup.string().required('Campo obrigatório'),
  number: yup.string().when('noNumber', {
    is: true,
    then: yup.string().optional().default(''),
    otherwise: yup.string().required('Campo obrigatório'),
  }),
  complement: yup.string().optional().default(''),
  noNumber: yup.boolean(),
});

export const UpdateProfileAddress = (): JSX.Element => {
  const api = useApi();
  const theme = useTheme();
  const navigation = useNavigation();
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { getStates, getCities, getCity, getState } = useAdresses();
  const { geolocationAddress } = useGeolocation();
  const { user } = useAuth();
  const { params } = useRoute<UseRoute<'UpdateProfileAddress'>>();

  const [states, setStates] = useState<SelectItem[]>([]);
  const [cities, setCities] = useState<SelectItem[]>([]);
  const [isLoadingCEP, setIsLoadingCEP] = useState(false);
  const [isToUpdateAddressFields, setIsToUpdateAddressFields] = useState(false);
  const [isStreet, setIsStreet] = useState(true);
  const [isNeighborhood, setIsNeighborhood] = useState(true);
  const [isCity, setIsCity] = useState(true);

  const handleGoBack = () => {
    if (params?.returnPage) {
      navigation.navigate(params.returnPage, { keepState: true });
    } else {
      navigation.navigate('ProfileAddress');
    }
  };

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { isValid, isSubmitting },
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      cep: '',
      street: '',
      neighborhood: '',
      complement: '',
      number: '',
      noNumber: false,
      state: {
        id: undefined,
        label: '',
        value: '',
      } as SelectItem,
      city: {
        id: undefined,
        label: '',
        value: '',
      } as SelectItem,
    },
  });

  const allFields = watch();

  useEffect(() => {
    if (states && isToUpdateAddressFields) {
      setIsToUpdateAddressFields(false);
      onSearchCEP(user.address?.postal_code);
    }
  }, [isToUpdateAddressFields, states]);

  useEffect(() => {
    if (allFields.state) {
      allFields.state?.id && onGetCities(allFields.state.id as number);
    }
  }, [allFields.state]);

  useEffect(() => {
    onGetStates();
    fulfillFields();

    register('state', { required: true });
    register('city', { required: true });
  }, []);

  useEffect(() => {
    const { cep } = geolocationAddress;

    if (cep) {
      const formatCep = cep.match?.(/\d/g)?.join('') ?? '';

      setValue('cep', formatCep);
      onSearchCEP(formatCep);
    }
  }, [geolocationAddress]);

  const fulfillFields = async () => {
    try {
      if (user) {
        const { address, neighborhood, city, complement, number, postal_code, state } =
          user.address;

        const stateData = await getState(state);

        if (stateData) {
          setValue(
            'state',
            {
              label: stateData.initials,
              value: stateData.initials,
              id: stateData.id,
            },
            { shouldValidate: true },
          );

          await trigger('state');

          const cityData = await getCity(stateData.id, city);
          if (cityData) {
            setValue(
              'city',
              {
                label: cityData.title,
                value: cityData.id,
                id: cityData.id,
              },
              { shouldValidate: true },
            );
            await trigger('city');
          }
        }

        setValue('street', address ?? '', { shouldValidate: !!address });
        setValue('neighborhood', neighborhood ?? '', { shouldValidate: !!neighborhood });
        setValue('complement', complement ?? '', { shouldValidate: !!complement });
        setValue('number', number ?? '', { shouldValidate: !!number });
        if (number === ' ') {
          setValue('noNumber', true);
        }
        setValue('cep', postal_code ?? '', { shouldValidate: !!postal_code });

        if (postal_code) {
          setIsToUpdateAddressFields(true);
        }
      }
    } catch (err) {
      console.error('error while fulfilling fields', err);
    }
  };

  const onGetStates = useCallback(async () => {
    try {
      const result = await getStates();

      setStates(
        result.map(({ id, initials }: { id: number; title: string; initials: string }) => ({
          id,
          label: initials,
          value: initials,
        })),
      );
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível listar os estados.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  }, [setStates, getStates]);

  const onGetCities = useCallback(
    async (cityId: number) => {
      try {
        const result = await getCities(cityId);

        setCities(
          result.map(({ id, title }: { id: number; title: string }) => ({
            label: capitalize(title),
            value: id,
          })),
        );
      } catch (error) {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível listar as cidades.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      }
    },
    [setCities, getCities],
  );

  const onSearchCEP = useCallback(
    async (cep: string) => {
      try {
        if (/00000-?000/.test(cep)) return;

        setIsLoadingCEP(true);
        const {
          data: { uf, city, route, sublocality },
        } = await api.getAddressByCep(cep);
        const currentState = states?.find?.((state) => state.value === uf) ?? {};
        const currentCity = await getCity(currentState.id, city);

        !route ? setIsStreet(false) : setIsStreet(true);
        !sublocality ? setIsNeighborhood(false) : setIsNeighborhood(true);
        !currentState ? setIsCity(false) : setIsCity(true);

        setValue('street', route);
        setValue('neighborhood', sublocality);
        setValue('state', currentState, { shouldValidate: true });

        if (currentCity) {
          setValue(
            'city',
            {
              id: currentCity.id,
              label: capitalize(currentCity.title),
              value: currentCity.id,
            },
            { shouldValidate: true },
          );
        }

        await trigger('city');
        await trigger('state');
      } catch (error) {
        showSweetAlert(
          'Ops, algo deu errado',
          'Não foi possível encontrar o CEP informado.',
          'error',
          false,
          false,
          {
            layout: 'helloUi',
            touchOutside: false,
            buttons: [
              {
                text: 'Ok',
                testID: 'accept-button',
                variant: 'successPrimary',
                onPress: () => hideSweetAlert(),
              },
            ],
          },
        );
      } finally {
        setIsLoadingCEP(false);
      }
    },
    [api, setValue, getCity, states],
  );

  const updateProfile = async (values) => {
    try {
      await api.updateProfileAddress({
        ...values,
        number: values.noNumber ? ' ' : values.number,
        cpf: extractDigits(user?.cpf) || undefined,
        state: values.state.value,
        city: values.city.label,
        isMandatory: true,
        firstAccess: true,
      });

      await AuthService.updateProfileData();

      showSweetAlert('Tudo certo!', 'Seu endereço foi atualizado', 'success', false, false, {
        layout: 'helloUi',
        touchOutside: false,
        buttons: [
          {
            text: 'Ok',
            testID: 'accept-button',
            variant: 'primary',
            onPress: () => {
              hideSweetAlert();
              handleGoBack();
            },
          },
        ],
      });
    } catch (error) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível atualizar seus dados.',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'Ok',
              testID: 'accept-button',
              variant: 'successPrimary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    }
  };

  const handleChangeEnableNumber = () => {
    if (!allFields.noNumber) {
      setValue('number', '');
    }
  };

  return (
    <PageWithCard
      simpleOptions={{
        title: 'Atualizar Endereço',
        onBack: handleGoBack,
      }}>
      <View className="mb-32">
        <GeolocationButton color="primary" />
      </View>
      <View className="mb-8 flex-row">
        <View className="w-1/2 pr-8">
          <Controller
            name="cep"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label="CEP"
                placeholder="00000-000"
                mask="99999-999"
                isLoading={isLoadingCEP}
                variant="mask"
                keyboardType="number-pad"
                value={value}
                isDisabled={isLoadingCEP}
                onChangeText={(value) => {
                  const cep = value?.match?.(/\d/g)?.join('');
                  onChange(cep);
                  cep?.length === 8 && onSearchCEP(cep);
                }}
                error={error?.message ?? ''}
              />
            )}
          />
        </View>
        <View className="w-1/2 pl-8">
          <Select
            disabled
            items={states}
            placeholder="Digite"
            label="Estado"
            value={allFields.state}
            onSelect={(state) => setValue('state', state, { shouldValidate: true })}
          />
        </View>
      </View>
      <View className="mb-16 flex-row">
        <TouchableOpacity
          className="w-auto"
          onPress={() => Linking.openURL(LINK_TO_SEARCH_CEP)}
          hitSlop={{ top: 10, right: 10, bottom: 10, left: 10 }}>
          <Typography
            variant="link"
            color="title"
            style={{
              fontSize: theme.isMobile ? 12 : 14,
              lineHeight: theme.isMobile ? 18 : 24,
              width: 'auto',
            }}>
            Não sei meu CEP
          </Typography>
        </TouchableOpacity>
      </View>

      <View className="mb-8">
        <Select
          disabled={isCity}
          items={cities}
          placeholder="Digite"
          label="Cidade"
          value={allFields.city}
          onSelect={(city) => setValue('city', city, { shouldValidate: true })}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="street"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              keyboardType="default"
              isDisabled={isStreet}
              label="Endereço"
              placeholder="Digite"
              value={value}
              onChangeText={onChange}
              error={error?.message ?? ''}
            />
          )}
        />
      </View>

      <View className="mb-8">
        <Controller
          name="neighborhood"
          control={control}
          defaultValue=""
          rules={{ required: true }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Input
              keyboardType="default"
              label="Bairro"
              isDisabled={isNeighborhood}
              placeholder="Digite"
              value={value}
              onChangeText={onChange}
              error={error?.message ?? ''}
            />
          )}
        />
      </View>

      <View className="mb-8 flex-row">
        <View className="w-1/2 pr-8">
          <Controller
            name="number"
            control={control}
            defaultValue=""
            disabled={allFields.noNumber}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                isDisabled={allFields.noNumber}
                keyboardType="number-pad"
                label="Número"
                placeholder="Digite"
                value={value}
                onChangeText={(text) => {
                  const number = text?.match?.(/\d/g)?.join('');
                  onChange(number);
                }}
                error={error?.message ?? ''}
              />
            )}
          />
        </View>
        <View className="w-1/2 justify-center pl-32">
          <Controller
            name="noNumber"
            control={control}
            defaultValue={false}
            rules={{ required: true }}
            render={({ field: { onChange } }) => (
              <Checkbox
                checked={watch('noNumber')}
                label="Sem número"
                onPress={() => {
                  handleChangeEnableNumber();
                  onChange(!watch('noNumber'));
                }}
              />
            )}
          />
        </View>
      </View>

      <View className="flex-row">
        <View className="w-1/2 pr-8">
          <Controller
            name="complement"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Input
                keyboardType="default"
                label="Complemento"
                placeholder="Digite"
                value={value}
                onChangeText={onChange}
                error={error?.message ?? ''}
              />
            )}
          />
        </View>
        <View className="w-1/2" />
      </View>
      <View className="mt-40 mobile:mt-24">
        <Button
          disabled={!isValid || isSubmitting}
          variant="primary"
          onPress={handleSubmit(updateProfile)}
          loading={isSubmitting}>
          Atualizar
        </Button>
      </View>
    </PageWithCard>
  );
};
