import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { Typography } from '~/components/@hello-ui';

type RequestDetailsStepItemProps = {
  title: string;
  children: string;
};

export const RequestDetailsStepItem = ({ title, children }: RequestDetailsStepItemProps) => {
  const theme = useTheme();

  return (
    <View className="border-t p-16" style={{ borderColor: theme.colors['gray-disabled'] }}>
      <Typography variant="body2" className="mb-8" style={{ color: theme.colors.placeholder }}>
        {title}
      </Typography>
      <Typography variant="body2" style={{ color: theme.colors.paragraph }}>
        {children}
      </Typography>
    </View>
  );
};
