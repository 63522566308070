import { API_URL } from '@env';
import { ApiBase } from '~/services/api/api-base';
import { LoginDomain } from '~/services/api/olimpo/domains/login-domain';
import { PartnersDomain } from '~/services/api/olimpo/domains/partners-domain';
import { ProfileDomain } from '~/services/api/olimpo/domains/profile-domain';
import { DeepLinkDomain } from '~/services/api/olimpo/domains/deeplink-domain';
import { SSOCertificateDomain } from '~/services/api/olimpo/domains/sso-certificate-domain';
import { SSOCertificateFullDomain } from '~/services/api/olimpo/domains/sso-certificate-full-domain';
import { RechargeDomain } from '~/services/api/olimpo/domains/recharge-domain';

export class OlimpoServiceClass extends ApiBase {
  login: LoginDomain;
  profile: ProfileDomain;
  partners: PartnersDomain;
  deepLink: DeepLinkDomain;
  ssoCertificate: SSOCertificateDomain;
  ssoCertificateFull: SSOCertificateFullDomain;
  recharge: RechargeDomain;

  constructor() {
    super({ baseUrl: API_URL });
    this.login = new LoginDomain(this);
    this.profile = new ProfileDomain(this);
    this.partners = new PartnersDomain(this);
    this.deepLink = new DeepLinkDomain(this);
    this.ssoCertificate = new SSOCertificateDomain(this);
    this.ssoCertificateFull = new SSOCertificateFullDomain(this);
    this.recharge = new RechargeDomain(this);
  }
}

export const OlimpoService = new OlimpoServiceClass();
