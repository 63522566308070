import React from 'react';
import { View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { clsx } from 'clsx';

type ProgressBarProps = {
  color?: string;
  active?: boolean;
  first?: boolean;
  last?: boolean;
  className?: string;
};

export const ProgressBar = ({ color, active, first, last }: ProgressBarProps) => {
  const theme = useTheme();
  return (
    <View className="h-4 w-full px-[2px]">
      <View
        className={clsx('flex-1', {
          'rounded-r-full': last,
          'rounded-l-full': first,
        })}
        style={{ backgroundColor: active ? color || theme.colors.primary : theme.colors.gray15 }}
      />
    </View>
  );
};
