import React from 'react';
import { Platform, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

export type StepProps = {
  step: number;
  color?: string;
  icon: React.FC<SvgProps>;
  currentStep?: number;
};

type CreateStepData = {
  defaultIcon: React.FC<SvgProps>;
  currentIcon: React.FC<SvgProps>;
  completedIcon: React.FC<SvgProps>;
};

const Step = ({ icon: Icon, step, currentStep = 0, color }: StepProps) => {
  const theme = useTheme();

  const backgroundColor =
    currentStep >= step ? (!color ? theme.colors.primary : color) : theme.colors.gray15;
  const textColor = currentStep >= step ? theme.colors.white : theme.colors['gray-disabled'];

  return (
    <View className="flex-row items-center justify-center">
      {step !== 0 && (
        <View
          className="h-[2px] w-[10px] desktop:w-20"
          style={{
            backgroundColor,
          }}
        />
      )}
      <View className="rounded-full p-8 desktop:p-16" style={{ backgroundColor }}>
        <Icon
          height={Platform.OS === 'web' ? 16 : 18}
          width={Platform.OS === 'web' ? 16 : 18}
          fill={textColor}
        />
      </View>
    </View>
  );
};

export const createStep = ({ completedIcon, currentIcon, defaultIcon }: CreateStepData) => {
  return ({ step, currentStep = 0, color }: Omit<StepProps, 'icon'>) => {
    const icon =
      step < currentStep ? completedIcon : step === currentStep ? currentIcon : defaultIcon;

    return <Step icon={icon} step={step} currentStep={currentStep} color={color} />;
  };
};
