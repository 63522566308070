import React, { type ReactNode } from 'react';
import { View } from 'react-native';

type ProgressStepsProps = {
  children: ReactNode;
};

export const ProgressSteps = ({ children }: ProgressStepsProps) => {
  return <View className={'mt-8 flex-row'}>{children}</View>;
};
