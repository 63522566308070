import React, { useCallback, useEffect, useState } from 'react';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import { Platform, View } from 'react-native';
import { useTheme } from 'styled-components/native';
import { RechargeValue } from '~/screens/TemPayRecharge/components/RechargeValue';
import {
  Button,
  Dialog,
  Loader,
  MobileAutoSpace,
  Select,
  Typography,
  useSweetAlert,
} from '~/components/@hello-ui';
import { SelectItem } from '~/components/@hello-ui/Select/types';
import { useApi } from '~/hooks/api';
import { useAuth } from '~/auth/legacy/useAuth';
import { capitalize, currency } from '~/utils/strings';
import { useRecharge } from '~/components/@tem-ui/Recharge';
import LockIcon from '~/components/Icons/LockIcon';
import { useTemPay } from '~/hooks/temPay';
import { UseRoute } from '~/@types/navigation/routes-helpers';

export const PaymentCreditCard = () => {
  const generateInstallments = (rechargeAmount: number) => {
    const installments =
      Math.floor(rechargeAmount / 20) <= 12 ? Math.floor(rechargeAmount / 20) : 12;
    return Array.from({ length: installments }, (value, idx) => idx + 1).map((installment) => ({
      currency: rechargeAmount / installment,
      label: `${installment}x de ${currency(rechargeAmount / installment, 'pt-BR', 'BRL', true)}`,
      value: installment,
    }));
  };

  const theme = useTheme();
  const navigation = useNavigation();
  const { user, partner } = useAuth();
  const [isLoadingCards, setIsLoadingCards] = useState(true);
  const { showSweetAlert, hideSweetAlert } = useSweetAlert();
  const { rechargeAmount, setRechargeStep } = useRecharge();
  const api = useApi();
  const { card } = useTemPay();

  const { params } = useRoute<UseRoute<'TemPayRecharge'>>();

  const [isToShowConfirmDialog, setIsToShowConfirmDialog] = useState(false);
  const [creditCards, setCreditCards] = useState([]);
  const [isRecharging, setIsRecharging] = useState(false);
  const [creditCard, setCreditCard] = useState<SelectItem>();
  const [installments, setInstallments] = useState<SelectItem>(
    generateInstallments(rechargeAmount)[0],
  );

  const goToRegisterCard = (extraParams?: Record<string, unknown>) => {
    navigation.navigate('RegisterCreditCard', {
      returnPage: 'TemPayRecharge',
      ...extraParams,
    });
  };

  const getCreditCards = async () => {
    try {
      const cardsRes = await api.getCreditCards(user?.cpf.replace(/\D/g, ''));

      if (cardsRes.data.length === 0) {
        goToRegisterCard({ automaticRedirect: true });
        return;
      }

      setCreditCards(
        cardsRes.data.map((card) => ({
          label: `${capitalize(card.brand ?? '')} **** ${card.last_four_digits}`,
          value: card.id,
        })),
      );
    } catch (err) {
      showSweetAlert(
        'Ops, algo deu errado',
        'Não foi possível carregar seus cartões. Tente novamente mais tarde',
        'error',
        false,
        false,
        {
          layout: 'helloUi',
          touchOutside: false,
          buttons: [
            {
              text: 'OK',
              variant: 'primary',
              onPress: () => hideSweetAlert(),
            },
          ],
        },
      );
    } finally {
      setIsLoadingCards(false);
    }
  };

  const onNext = () => {
    setIsToShowConfirmDialog(true);
  };

  const getErrorMessage = (status: number, data) => {
    switch (status) {
      case 500:
        return 'Ocorreu um erro inesperado';
      case 400:
      case 409:
        return data?.message;
      default:
        return 'Ocorreu um erro inesperado';
    }
  };

  const showErrorAlert = (message: string) => {
    showSweetAlert('Ops, algo deu errado', message, 'error', false, false, {
      layout: 'helloUi',
      touchOutside: false,
      buttons: [
        {
          text: 'OK',
          variant: 'primary',
          onPress: () => hideSweetAlert(),
        },
      ],
    });
  };

  const onConfirm = async () => {
    setIsRecharging(true);
    try {
      const payload = {
        amount: parseInt((rechargeAmount * 100).toFixed(0)),
        installments: installments.value as number,
        credit_card: {
          id: creditCard!.value,
        },
        tem_card_number: card?.numero_cartao,
        onix_code: partner?.onix_code,
      };

      // Recarga com cartão de crédito (Olimpo - Integração com TemPay)
      await api.rechargeWithRegisteredCreditCard(payload);

      setRechargeStep(4);
    } catch (err) {
      const status = err.response.status;
      const errorMessage = getErrorMessage(status, err.response.data);

      showErrorAlert(errorMessage);
    } finally {
      setIsRecharging(false);
      setIsToShowConfirmDialog(false);
    }
  };

  useEffect(() => {
    getCreditCards();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (params?.keepState) {
        getCreditCards();
      }
    }, [params]),
  );

  return (
    <>
      {isLoadingCards ? (
        <View className="h-full max-h-[500px]">
          <Loader variant="circle-loader" color={theme.colors.primary} />
        </View>
      ) : (
        <>
          <RechargeValue className="mb-40" />
          <Typography variant="title" className="mb-40 text-center mobile:mb-24">
            Pagamento
          </Typography>
          <Select
            items={creditCards}
            placeholder="Selecione"
            value={creditCard}
            onSelect={(item) => setCreditCard(item)}
          />
          <Typography variant="link" className="mb-24 mt-8" onPress={() => goToRegisterCard()}>
            Cadastrar novo cartão
          </Typography>
          <Select
            items={generateInstallments(rechargeAmount)}
            placeholder="Selecione"
            value={installments}
            onSelect={(item) => setInstallments(item)}
          />
          <MobileAutoSpace heightMobile={40} heightDesktop={80} />
          <Button variant="primary" disabled={creditCard === undefined} onPress={onNext}>
            Próximo
          </Button>
        </>
      )}

      <Dialog visible={isToShowConfirmDialog} onClose={() => setIsToShowConfirmDialog(false)}>
        <View className="flex-1 items-center justify-center">
          <View className="mb-16 items-center">
            <LockIcon width={32} height={32} />
          </View>

          <Typography variant="subtitle" className="mb-8 text-center">
            Pagamento Seguro
          </Typography>

          <Typography variant="body2" className="mb-16 text-center">
            Só falta você confirmar o pagamento da sua recarga
          </Typography>

          <View className="mb-16 self-stretch rounded-lg bg-background-gray p-24 mobile:p-16">
            <Typography variant="body2" className="text-center">
              {installments.value}x de
            </Typography>
            <View className="flex-row items-end justify-center">
              <Typography
                variant="helperText"
                className="mb-4 mr-4"
                color="paragraph"
                style={{
                  fontFamily: Platform.OS === 'web' ? 'Poppins' : 'Poppins-Bold',
                  fontWeight: Platform.OS === 'web' ? 'bold' : undefined,
                }}>
                R$
              </Typography>
              <Typography variant="h3" color="paragraph">
                {currency(rechargeAmount / (installments.value ?? 1), 'pt-BR', 'BRL', true, {
                  currencyDisplay: 'code',
                })
                  .replace('BRL', '')
                  .trim()}
              </Typography>
            </View>
          </View>

          <Button
            variant="primary"
            onPress={onConfirm}
            className="mb-16 desktop:mb-0"
            loading={isRecharging}
            disabled={isRecharging}>
            Confirmar Pagamento
          </Button>
        </View>
      </Dialog>
    </>
  );
};
