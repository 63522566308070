import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';
import { IconProps } from '~/components/Icons/types';

const HouseExclamationIcon = ({ color, height, width }: IconProps) => {
  const theme = useTheme();
  return (
    <Svg width={width ?? 24} height={height ?? 24} fill="none">
      <Path
        d="M17 9.99962C13.141 9.99962 10 13.1406 10 16.9996C10 20.8586 13.141 23.9996 17 23.9996C20.859 23.9996 24 20.8586 24 16.9996C24 13.1406 20.859 9.99962 17 9.99962ZM17 21.9996C14.243 21.9996 12 19.7566 12 16.9996C12 14.2426 14.243 11.9996 17 11.9996C19.757 11.9996 22 14.2426 22 16.9996C22 19.7566 19.757 21.9996 17 21.9996ZM16 15.9996V13.9996C16 13.4466 16.447 12.9996 17 12.9996C17.553 12.9996 18 13.4466 18 13.9996V15.9996C18 16.5526 17.553 16.9996 17 16.9996C16.447 16.9996 16 16.5526 16 15.9996ZM18.5 19.4996C18.5 20.3276 17.828 20.9996 17 20.9996C16.172 20.9996 15.5 20.3276 15.5 19.4996C15.5 18.6716 16.172 17.9996 17 17.9996C17.828 17.9996 18.5 18.6716 18.5 19.4996ZM10 22.9996C10 23.5526 9.552 23.9996 9 23.9996H5C2.243 23.9996 0 21.7566 0 18.9996V9.72362C0 8.05862 0.824 6.50962 2.204 5.57862L9.203 0.854617C10.902 -0.291383 13.098 -0.291383 14.797 0.854617L22 5.74762C22.99 6.49562 23.692 7.59162 23.918 8.81762C24.019 9.36062 23.659 9.88162 23.116 9.98262C22.575 10.0856 22.051 9.72362 21.951 9.18062C21.805 8.39262 21.342 7.68362 20.679 7.23662L13.678 2.51262C12.658 1.82362 11.342 1.82362 10.323 2.51262L3.322 7.23662C2.494 7.79562 2 8.72362 2 9.72262V18.9986C2 20.6526 3.346 21.9986 5 21.9986H9C9.552 21.9986 10 22.4466 10 22.9996Z"
        fill={color ?? theme.colors.primary}
      />
    </Svg>
  );
};

export default HouseExclamationIcon;
